import { useQueryClient } from "@tanstack/react-query";
import SortContext from "context/SortContext";
import React from "react";

export const useRemoveDesings = () => {
  const queryClient = useQueryClient();
  const { setIsBtnEnable } = React.useContext(SortContext);

  //remove the designs
  const handleRemoveDesign = (setId, desingId) => {
    const removedDesignsBySet = JSON.parse(localStorage.getItem("removedDesignsBySet")) || {};
    const updatedSetRemovals = [...(removedDesignsBySet[setId] || []), desingId];
    removedDesignsBySet[setId] = updatedSetRemovals;
    localStorage.setItem("removedDesignsBySet", JSON.stringify(removedDesignsBySet));

    // update the cache
    queryClient.setQueryData(["get-designs", setId], (oldData) => {
      if (!oldData) return oldData;
      const updatedDesigns = oldData?.set?.designs?.filter((design) => design._id !== desingId);
      if (updatedDesigns.length === oldData.set.designs.length) {
        return oldData;
      }
      return {
        ...oldData,
        set: {
          ...oldData.set,
          designs: updatedDesigns,
        },
      };
    });
    setIsBtnEnable(true);
  };

  //archives the desings
  const handleArchiveSet = (setId) => {
    const archivedDesigns = JSON.parse(localStorage.getItem("archivedDesigns")) || [];
    if (!archivedDesigns.includes(setId)) {
      archivedDesigns.push(setId);
      localStorage.setItem("archivedDesigns", JSON.stringify(archivedDesigns));
    }

    // const queries = queryClient.getQueriesData("sets"); //get all sets query
    // queries.forEach(([queryKey, oldData]) => {
    //   if (oldData) {
    //     // Update the data by filtering out the archived setId
    //     const updatedPages = oldData.pages.map((page) => {
    //       return {
    //         ...page,
    //         sets: page.sets.filter((set) => set._id !== setId),
    //       };
    //     });

    //     // Update the cache
    //     queryClient.setQueryData(queryKey, {
    //       ...oldData,
    //       pages: updatedPages,
    //     });
    //   }
    // });

    // Get all queries that start with "sets"
    const queries = queryClient.getQueriesData("sets");

    // Loop through all "sets" queries and update the cache
    queries.forEach(([queryKey, oldData]) => {
      if (oldData && Array.isArray(oldData.pages)) {
        // Ensure oldData.pages is defined and is an array
        const updatedPages = oldData.pages.map((page) => {
          return {
            ...page,
            sets: page.sets ? page.sets.filter((set) => set._id !== setId) : [], // Check if page.sets is defined
          };
        });

        // Update the cache for this query
        queryClient.setQueryData(queryKey, {
          ...oldData,
          pages: updatedPages,
        });
      } else {
        console.warn(`Old data for query key ${queryKey} is not in the expected format:`, oldData);
      }
    });
    setIsBtnEnable(true);
  };

  const createPayload = () => {
    const removedDesignsBySet = JSON.parse(localStorage.getItem("removedDesignsBySet")) || {};
    const archivedDesigns = JSON.parse(localStorage.getItem("archivedDesigns")) || [];

    const payload = {};

    // Only add `setsToRemoveDesignFrom` if it has data
    if (Object.keys(removedDesignsBySet).length > 0) {
      payload.setsToRemoveDesignFrom = removedDesignsBySet;
    }

    // Only add `designIdsToArchive` if it has data
    if (archivedDesigns.length > 0) {
      payload.designIdsToArchive = archivedDesigns;
    }

    return payload;
  };

  const createAddDesignsPayload = (setId) => {
    const payload = { setsToAddDesignTo: {} };
    const addedDesignsData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    payload.setsToAddDesignTo[setId] = addedDesignsData.map((design) => design.id);
    // return payload;

    const result = createPayload();

    return { ...payload, ...result };
  };

  const handleCancel = () => {
    queryClient.removeQueries(["sets"]);
    queryClient.removeQueries(["get-designs"]);
    localStorage.removeItem("archivedDesigns");
    localStorage.removeItem("removedDesignsBySet");
  };

  return {
    handleRemoveDesign,
    handleArchiveSet,
    createPayload,
    createAddDesignsPayload,
    handleCancel,
  };
};

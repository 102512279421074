import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calender.css";
import Modal from "components/ui/Modal";
import { getIcon } from "utils/iconsConfig";
import Button from "components/ui/Button";

const CalenderComp = ({ open, handleClose }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleApplyDate = () => {
    if (selectedDate) {
      console.warn(`Selected Date: ${selectedDate.toLocaleDateString()}`);
    } else {
      console.warn("Please select a date.");
    }
    handleClose();
  };

  useEffect(() => {
    return () => {
      setSelectedDate(null);
    };
  }, []);

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "430px", height: "468px", mr: 60 }}>
      <div className="calendar-popup">
        <DatePicker
          inline
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          calendarClassName="custom-calendar"
          renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
            <div className="custom-header">
              <div className="header-title">
                {date.toLocaleString("default", { month: "long" })} {date.getFullYear()}
              </div>

              <div className="header-buttons">
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="prev-button">
                  {getIcon("prev")}
                </button>
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="next-button">
                  {getIcon("next")}
                </button>
              </div>
            </div>
          )}
        />

        <Button type="submit" variant="outlined" size="large" fullWidth className={`btns  ${selectedDate ? "btns-green" : "btn-default"}`} onClick={handleApplyDate}>
          Apply Date
        </Button>
      </div>
    </Modal>
  );
};

export default CalenderComp;

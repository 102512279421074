import React, { useEffect, useState } from "react";
import { Button, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { getIcon } from "utils/iconsConfig";
import { getOrderDetails } from "../apis/get-order-detail";
import ImageModal from "components/ui/ImageModal";
import Image from "assets/img/prod2.png";

const StyledButton = styled(Button)(({ variant, disabled }) => ({
  width: variant === "save" ? "168px" : variant === "cancel" ? "110px" : variant === "save-rights" ? "150px" : "160px",
  fontFamily: variant === "save-rights" ? "poppins" : "karla",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "21.04px",
  height: "41px",
  borderRadius: "5px",
  color: disabled ? "#B0B0B0" : variant === "save" ? "#50C878" : variant === "cancel" ? "#E77B7B" : variant === "save-rights" ? "#50C878" : "#FFF",

  border: `1px solid ${disabled ? "#B0B0B0" : variant === "save" ? "#50C878" : variant === "cancel" ? "#E77B7B" : variant === "save-rights" ? "#50C878" : "#fff"}`,
  textTransform: "none",
  backgroundColor: "transparent",

  "&:hover": {
    backgroundColor: disabled ? "transparent" : variant === "save" ? "#50C878" : variant === "cancel" ? "#E77B7B" : variant === "save-rights" ? "#50C878" : "#fff",
    color: disabled ? "#B0B0B0" : variant === "save" ? "#fff" : variant === "cancel" ? "#fff" : variant === "save-rights" ? "#fff" : "#000",
  },
}));

const StyledTableContainer = styled(TableContainer)(({}) => ({
  backgroundColor: "transparent",
  maxHeight: "76.5vh",
  position: "relative",
  marginBottom: "0px",
  // paddingBottom: "5px",
  border: "1px solid #fff",
  // Hide scrollbars for Webkit browsers
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },

  // Hide scrollbars for Firefox
  scrollbarWidth: "none", // For Firefox
}));

const StyledTable = styled(Table)(({}) => ({
  "& thead": {
    "& th": {
      lineHeight: "22px",
      color: "#FFFFFF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
    },
  },
  "& tbody": {
    "& td": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
      color: "#FFFFFF",
      textAlign: "center",
      border: "1px solid #fff",
      padding: 0,
      padding: "10px",
      "&:first-child": {
        borderLeft: "none",
      },
      "&:last-child": {
        borderRight: "none",
      },
    },
  },
  "& th": {
    backgroundColor: "#444444",
    height: "50px !important",
  },
  "& tfoot": {
    "& td": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
      textAlign: "center",
    },
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({}) => ({
  fontFamily: "Poppins !important",
  position: "sticky",
  top: 0,
  padding: "10px",
  paddingLeft: "15px",
  paddingRight: "15px",
  border: "none",
  zIndex: 1,

  // "&:first-child": {
  //   borderTopLeftRadius: "5px",
  //   borderBottomLeftRadius: "5px",
  // },
  // "&:last-child": {
  //   borderTopRightRadius: "5px",
  //   borderBottomRightRadius: "5px",
  // },
}));

const StyledTableBodyCell = styled(TableCell)(({ color }) => ({
  fontFamily: "Karla !important",
  padding: "10px",
  paddingLeft: "5px",
  paddingRight: "5px",
  border: "none",
  color: `${color} !important`,
  "&:first-child": {},
  "&:last-child": {},
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "& td": {
    // padding: "20px",
    // paddingTop: "0px",
  },
  "&:first-of-type td": {
    // padding: "20px",
    // paddingTop: "15px",
  },
}));

const TableSkeleton = ({ columns, rows = 5 }) => (
  <>
    {Array.from(new Array(rows)).map((_, rowIndex) => (
      <StyledTableRow key={`skeleton-${rowIndex}`}>
        {Array.from(new Array(columns)).map((_, cellIndex) => (
          <StyledTableBodyCell key={`skeleton-cell-${cellIndex}`}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} animation="wave" width={"80%"} height={"90%"} />
          </StyledTableBodyCell>
        ))}
      </StyledTableRow>
    ))}
  </>
);

const columns = [
  { id: 1, label: "Design No." },
  { id: 2, label: "Diamond Qty" },
  { id: 3, label: "Diamond W/P" },
  { id: 4, label: "Metal Qty & Color" },
  { id: 5, label: "Gold Weight N/G" },
  { id: 6, label: "Size" },
  { id: 7, label: "labour" },
  { id: 8, label: "View Design" },
];

const OrderDetails = () => {
  const [loading, setLoading] = useState(true);
  const [ordersData, setOrdersData] = useState([]);

  const [imageModal, setImageModal] = React.useState({ isOpen: false, imgUrl: null, designNo: null });

  const handleOpenImageModal = React.useCallback((imgUrl, designNo) => {
    setImageModal({ isOpen: true, imgUrl, designNo });
  }, []);

  const handleCloseImageModal = React.useCallback(() => {
    setImageModal({ isOpen: false, imgUrl: null, designNo: null });
  }, []);

  const str = "04/08/24-05:38:56 PM, +91 8856049691 (Dholokiya Jewels), #560852 (Tannshiq Catalog)";

  useEffect(() => {
    getOrderDetails()
      .then((res) => {
        setOrdersData(res);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="Grid-padding">
      <div
        style={{
          // border: "1px solid red",
          height: "42px",
          alignItems: "center",
          margin: "20px 0 20px 0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "36px",
          }}
        >
          {str}
        </p>
        <StyledButton onClick={() => alert("Hii")}>Download Order</StyledButton>
      </div>

      <StyledTableContainer>
        <StyledTable>
          {/* header */}
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableHeaderCell key={column.id}>{column.label}</StyledTableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          {/* end header */}

          <TableBody>
            {loading ? (
              <TableSkeleton columns={8} rows={5} />
            ) : (
              ordersData?.map((order, _ind) => {
                const { _id, designNo, diamondQty, diamondWP, metalQty_Color, goldWeight, size, labour } = order;
                return (
                  <StyledTableRow key={_id}>
                    <StyledTableBodyCell>{designNo}</StyledTableBodyCell>
                    <StyledTableBodyCell>{diamondQty}</StyledTableBodyCell>
                    <StyledTableBodyCell>{diamondWP}</StyledTableBodyCell>
                    <StyledTableBodyCell>{metalQty_Color}</StyledTableBodyCell>
                    <StyledTableBodyCell>{goldWeight}</StyledTableBodyCell>
                    <StyledTableBodyCell>{size}</StyledTableBodyCell>
                    <StyledTableBodyCell>{labour}</StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <IconButton onClick={() => handleOpenImageModal(Image, designNo)}>{getIcon("eye")}</IconButton>
                    </StyledTableBodyCell>
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>

          <tfoot>
            <StyledTableRow>
              <StyledTableBodyCell>Grand Total</StyledTableBodyCell>
              <StyledTableBodyCell></StyledTableBodyCell>
              <StyledTableBodyCell>5000/10.95</StyledTableBodyCell>
              <StyledTableBodyCell></StyledTableBodyCell>
              <StyledTableBodyCell>500/455.95</StyledTableBodyCell>
              <StyledTableBodyCell></StyledTableBodyCell>
              <StyledTableBodyCell></StyledTableBodyCell>
              <StyledTableBodyCell></StyledTableBodyCell>
            </StyledTableRow>
          </tfoot>
        </StyledTable>
      </StyledTableContainer>

      <ImageModal open={imageModal.isOpen} handleClose={handleCloseImageModal} data={imageModal} />
    </div>
  );
};

export default OrderDetails;

import React from "react";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import Modal from "./Modal";
import Button from "./Button";
import SortContext from "context/SortContext";

const SortModal = ({ open, handleClose, title, list, isBtn }) => {
  const { setSelectedSortValue, selectedSortValue } = React.useContext(SortContext);

  const onChange = (event) => {
    const value = event.target.value;
    setSelectedSortValue((prev) => (prev === value ? "" : value));
    handleClose();
  };

  return (
    <Modal open={open} handleClose={handleClose} style={{ width: "auto" }}>
      <Typography id="modal-drawer-title" variant="h5" style={{ fontFamily: "karla", fontWeight: "500" }}>
        {title}
      </Typography>
      <Box mt={"20px"} p={0}>
        <FormControl>
          <RadioGroup value={selectedSortValue}>
            {list?.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Radio disableRipple onClick={onChange} disableFocusRipple sx={{ color: "black", "&.Mui-checked": { color: "black" }, paddingLeft: "0px" }} />}
                label={item.name}
                sx={{ color: "black", margin: 0, paddingBottom: 0.5, paddingTop: 0.6, gap: "1rem" }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {isBtn && (
          <Box display="flex" justifyContent="center" width="100%" mt={1}>
            <Button
              variant="contained"
              // disabled={!selectedValue}
              sx={{
                backgroundColor: selectedSortValue ? "#50C878" : "#444444",
                "&:hover": {
                  backgroundColor: selectedSortValue ? "#50C878" : "#444444",
                },
                width: "100%",
                height: "50px",
                borderRadius: "5px",
              }}
            >
              View Collection
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default SortModal;

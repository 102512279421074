import React, { useEffect } from "react";
import RenderSubCategories from "features/Category/subcategory/components/SubCategories";
import SortContext from "context/SortContext";

const SubCategories = () => {
  const { setSortOptions, setSelectedSortValue, setSearch, search } = React.useContext(SortContext);

  React.useEffect(() => {
    setSearch("");

    setSortOptions([
      { id: 1, name: "Alphabetical Order (A to Z)", sortBy: "alphabetical", order: "asc" },
      { id: 2, name: "Alphabetical Order (Z to A)", sortBy: "alphabetical", order: "desc" },
      { id: 3, name: "Design Quantity (High to Low)", sortBy: "quantity", order: "desc" },
      { id: 4, name: "Design Quantity (Low to High)", sortBy: "quantity", order: "asc" },
    ]);

    return () => {
      setSortOptions([]);
      setSelectedSortValue(null);
      setSearch("");
    };
  }, []);

  return <RenderSubCategories />;
};

export default SubCategories;

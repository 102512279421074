import { ProductCard } from "components/ui";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { ReactComponent as Diamond } from "assets/icons/diamond.svg";
import { ReactComponent as Babor } from "assets/icons/babor.svg";
import { ReactComponent as Gold } from "assets/icons/gold.svg";
import { ReactComponent as GoldIcon } from "assets/icons/small.svg";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "context/GlobalContext";
import { ReactComponent as DateIcon } from "assets/icons/date.svg";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import { transformData, transformDataForCartPage, transformDataForTrayModal } from "utils/transformdata";
import { usePermissions } from "context/PermissionsContext";
import { findSpecificPage, hasAccessPage } from "utils/checkUserRigths";

function Cart() {
  const location = useLocation();
  const isEdit = location?.state?.innerChnages;
  const [state, setState] = useState({});
  const { setCreateBtn, setCreateBtnName, setCreateBtnOption, setCountCart, countCart } = useGlobalContext();
  const [cartData, setCartData] = useState([]);
  const [removeProductModal, setRemoveProductModal] = useState(false);
  const [productToRemove, setProductToRemove] = useState(null);
  const permission = usePermissions();
  const hasAccessViewCategory = hasAccessPage(findSpecificPage("Category", permission), "view");
  const hasAccessViewCollection = hasAccessPage(findSpecificPage("Collection", permission), "view");
  const hasAccessViewSets = hasAccessPage(findSpecificPage("Sets", permission), "view");

  const list = [
    {
      id: 1,
      icon: <Gold />,
      text: "gold",
      // iconBtn: <Collection />,
      //   click: () => handleCollectionOpen(),
    },
    {
      id: 2,
      icon: <Diamond />,
      text: "diamond",
      // iconBtn: <Sets />,
      //   click: () => toggleDrawer(),
    },
    {
      id: 3,
      icon: <GoldIcon />,
      text: "silver",
    },

    {
      id: 4,
      icon: <Babor />,
      text: "user",
    },
    { id: 5, icon: <DateIcon />, text: "date" },
  ];

  const findBackPath = (path) => {
    if (!path) return "";

    const data = path.split("/");
    const isPresent = "/" + data.filter((item) => item === "Catalog" || item === "Sets" || item === "Collection")[0];

    return isPresent;
  };

  const handlemodalOpen = (product) => {
    setProductToRemove(product);
    setRemoveProductModal(true);
  };

  const removeCart = () => {
    if (productToRemove) {
      let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
      const updatedCartData = getCartData.filter((item) => {
        const cartItemId = item.id || item._id;
        const productToRemoveId = productToRemove.id || productToRemove._id;
        return cartItemId !== productToRemoveId;
      });
      sessionStorage.setItem("CartData", JSON.stringify(updatedCartData));
      setCartData(updatedCartData);
      setCountCart((prevCount) => prevCount - 1);
      setRemoveProductModal(false);
      setProductToRemove(null);
    }
  };

  const btnInfo = [
    {
      btnName: "Remove",
      bgColor: "#E77B7B",
      click: removeCart,
    },
    {
      btnName: "Keep",
      bgColor: "#50C878",
      click: () => setRemoveProductModal(false),
    },
  ];

  useEffect(() => {
    let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    const uniqueCartIds = [...new Set(getCartData.map((item) => item._id || item.id))];
    getCartData = getCartData.filter((item, index) => {
      return uniqueCartIds.indexOf(item._id || item.id) === index;
    });
    sessionStorage.setItem("CartData", JSON.stringify(getCartData));
    setCartData(getCartData);
    setCountCart(getCartData.length);
  }, [countCart]);

  useEffect(() => {
    setCreateBtn(true);
    setCreateBtnName("Add Design");
    switch (findBackPath(state?.backPath)) {
      case "/Catalog": {
        setCreateBtnOption([
          { option: "By Category", link: "/Category", view: hasAccessViewCategory },
          { option: "By Collection", link: "/Collection", view: hasAccessViewCollection },
          { option: "By Sets", link: "/Sets", view: hasAccessViewSets },
        ]);
        break;
      }
      case "/Collection": {
        setCreateBtnOption([{ option: "By Category", link: "/Category", view: hasAccessViewCategory }]);
        break;
      }
      case "/Sets": {
        setCreateBtnOption([
          { option: "By Category", link: "/Category", view: hasAccessViewCategory },
          { option: "By Collection", link: "/Collection", view: hasAccessViewCollection },
        ]);
        break;
      }
    }

    return () => {
      setCreateBtn(false);
      setCreateBtnName("");
      setCreateBtnOption([]);
    };
  }, [location, state, hasAccessViewCategory, hasAccessViewCollection, hasAccessViewSets]);

  useEffect(() => {
    setState(location.state);
  }, [location]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        // alignItems="center"
        // spacing={5}
        sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "32px", rowGap: "32px" }}
        className="Grid-padding"
        // sx={{ padding: "10px", paddingTop: "5px" }}
      >
        {cartData?.map((cart, index) => {
          // let newData = transformData(cart);

          let newData = transformDataForCartPage(cart);
          // let newData = cart;

          return (
            <Grid key={`${newData.id}-${index}` || `${newData._id}-${index}`} item className="product">
              <ProductCard
                list={list}
                data={newData}
                btnTilte="Remove"
                isBtn={true}
                removeCart={handlemodalOpen}
                selectedCartProducts={newData}
                // style={{ maxWidth: isEdit ? "420px" : "420px", minWidth: isEdit ? "400px" : "380px" }}
                style={{ maxWidth: isEdit ? "430px" : "430px", minWidth: isEdit ? "430px" : "430px" }}
              />
            </Grid>
          );
        })}
      </Grid>
      <RemoveModal open={removeProductModal} setOpen={setRemoveProductModal} btnInfo={btnInfo} heading="Do you want to Remove the Design?" />
    </>
  );
}

export default Cart;

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TrayModal from "components/ui/TrayModal";
import SortContext from "context/SortContext";
import RemoveModal from "components/ui/RemoveModal/RemoveModal";
import SetCard from "components/ui/SetCard";
import { useGlobalContext } from "context/GlobalContext";
import { useSets } from "../api/get-sets";
import { transformSetData } from "utils/transformdata";
import { useInView } from "react-intersection-observer";
import { useRemoveDesings } from "../hooks/useRemoveDesigns";
import ProductSkeleton from "components/ui/Skeleton/ProductSkeleton";

const Sets = ({ innerChnages, location }) => {
  const { isEdit, selectedSortValue, sortOptions, search } = React.useContext(SortContext);
  const [showTrayModal, setShowTrayModal] = React.useState(false);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [currentSet, setCurrentSet] = useState({});
  const [setsbtnTitles, setSetsBtnTitles] = useState({});
  const { setCountCart, countCart } = useGlobalContext();
  const selectedSetRef = React.useRef(null);
  const { handleArchiveSet } = useRemoveDesings();
  const [isPresent, setIsPresent] = useState({});

  const [viewset, setViewset] = React.useState({
    id: "",
  });

  //open the archive modal
  const openArchiveModal = (setId) => {
    selectedSetRef.current = setId;
    setRemoveModal(true);
  };

  const handleArchive = React.useCallback(() => {
    if (selectedSetRef.current) {
      handleArchiveSet(selectedSetRef.current);
      setRemoveModal(false);
    } else {
      console.error("No set ID selected for archiving");
    }
  }, [handleArchiveSet]);

  const modalBtnInfo = React.useMemo(
    () => [
      {
        btnName: "Archive",
        bgColor: "#E77B7B",
        click: handleArchive,
      },
      {
        btnName: "Keep",
        bgColor: "#50C878",
        click: () => setRemoveModal(false),
      },
    ],
    []
  );

  //fetch the sets data
  const selectedOption = sortOptions.find((option) => option.id == selectedSortValue);
  const selectedSortBY = selectedOption?.sortBy;
  const selectedSortOrder = selectedOption?.order;

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error } = useSets({
    limit: 12,
    sortBy: selectedSortBY,
    order: selectedSortOrder,
    searchTerm: search,
  });
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1.0,
  });

  //end fetch code

  const toggleDrawer = (data, set) => {
    setCurrentSet(set?.designs[0]);
    setShowTrayModal(true);
    setViewset({
      id: data,
    });
  };

  const handleAddSetButton = (set) => {
    let getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
    let updateCatalogProductsData = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];

    setSetsBtnTitles((preTitles) => {
      if (setsbtnTitles[set._id] == "Added") {
        let filterCardData = [];
        if (getCartData && getCartData.length > 0 && updateCatalogProductsData && updateCatalogProductsData.length > 0) {
          // let findInUpdateCatalog = [...updateCatalogProductsData, ...getCartData].filter((item) => set.designs[0].some((curElm) => (curElm.id || curElm._id) === (item.id || item._id)));
          let findInUpdateCatalog = [...getCartData].filter((item) => !set.designs[0].some((curElm) => (curElm.id || curElm._id) === (item.id || item._id)));

          filterCardData = [...findInUpdateCatalog].filter((item) => !set.designs[0].some((curElm) => (curElm.id || curElm._id) === (item.id || item._id)));
        } else {
          filterCardData = getCartData.filter((item) => !set.designs[0].some((curElm) => (curElm.id || curElm._id) === (item.id || item._id)));
        }
        let filterLength = getCartData.length - filterCardData.length;
        sessionStorage.setItem("CartData", JSON.stringify(filterCardData));
        setCountCart((prevCount) => prevCount - filterLength);
        return {
          ...preTitles,
          [set._id]: "Add",
        };
      } else {
        let productsToAdd = [];
        if (updateCatalogProductsData && updateCatalogProductsData.length > 0) {
          // productsToAdd = set.designs[0].filter((product) => ![...updateCatalogProductsData].some((cartItem) => (cartItem.id || cartItem._id) === (product.id || product._id)));
          productsToAdd = set.designs[0].filter((product) => ![...getCartData, ...updateCatalogProductsData].some((cartItem) => (cartItem.id || cartItem._id) === (product.id || product._id)));
        } else {
          productsToAdd = set.designs[0].filter((product) => !getCartData.some((cartItem) => (cartItem.id || cartItem._id) === (product.id || product._id)));
        }
        getCartData = [...getCartData, ...productsToAdd];
        sessionStorage.setItem("CartData", JSON.stringify(getCartData));
        setCountCart((prevCount) => prevCount + productsToAdd.length);
        return {
          ...preTitles,
          [set._id]: "Added",
        };
      }
    });
  };

  useEffect(() => {
    if (location?.state?.innerChnages && location?.state?.cataloguUpdate) {
      const updateCatalogProductsData = JSON.parse(sessionStorage.getItem("updateProductsData")) || [];
      const getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
      const alredyPresent = {};
      const newSetsBtnTitles = {};
      if (data?.pages) {
        data.pages.forEach((page) => {
          page?.sets?.forEach((curSet) => {
            const allProductsInCatalog = curSet?.designs[0]?.every((product) => updateCatalogProductsData.some((catalogItem) => (catalogItem._id || catalogItem.id) === product._id));

            newSetsBtnTitles[curSet?._id] = allProductsInCatalog ? "Added" : "Add";
            alredyPresent[curSet?._id] = allProductsInCatalog ? true : false;

            if (getCartData && getCartData.length > 0) {
              const allProductsInCart = curSet?.designs[0]?.every((product) => getCartData.some((cartItem) => (cartItem._id || cartItem.id) === product._id));
              newSetsBtnTitles[curSet?._id] = allProductsInCart ? "Added" : "Add";
            }

            if (getCartData && getCartData.length > 0 && updateCatalogProductsData && updateCatalogProductsData.length > 0) {
              let newCartData = [...getCartData, ...updateCatalogProductsData];
              const allProductsInCart = curSet?.designs[0]?.every((product) => newCartData.some((cartItem) => (cartItem._id || cartItem.id) === product._id));
              newSetsBtnTitles[curSet?._id] = allProductsInCart ? "Added" : "Add";
            }
          });
        });

        // Only update state if the values are different
        setSetsBtnTitles((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(newSetsBtnTitles)) {
            return newSetsBtnTitles;
          }
          return prev;
        });

        setIsPresent((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(alredyPresent)) {
            return alredyPresent;
          }
          return prev;
        });
      }
    } else {
      const getCartData = JSON.parse(sessionStorage.getItem("CartData")) || [];
      const newSetsBtnTitles = {};
      if (data?.pages) {
        data.pages.forEach((page) => {
          page?.sets?.forEach((curSet) => {
            const allProductsInCart = curSet?.designs[0]?.every((product) => getCartData.some((cartItem) => (cartItem._id || cartItem.id) === product._id));
            //  const allProductsInCatalog = curSet?.designs[0]?.every((product) => updateCatalogProductsData.some((catalogItem) => (catalogItem._id || catalogItem.id) === product._id));

            newSetsBtnTitles[curSet?._id] = allProductsInCart ? "Added" : "Add";
          });
        });

        // Only update state if the values are different
        setSetsBtnTitles((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(newSetsBtnTitles)) {
            return newSetsBtnTitles;
          }
          return prev;
        });
      }
      // setSetsBtnTitles((prevSetsBtnTitles) => {
      //   const newSetsBtnTitles = { ...prevSetsBtnTitles };
      //   data?.pages?.forEach((page) => {
      //     page?.sets?.forEach((curSet) => {
      //       const allProductsInCart = curSet?.designs[0]?.every((product) => getCartData.some((cartItem) => (cartItem._id || cartItem.id) === product._id));
      //       newSetsBtnTitles[curSet?._id] = allProductsInCart ? "Added" : "Add";
      //     });
      //   });
      //   return newSetsBtnTitles;
      // });
    }
  }, [data, setsbtnTitles, showTrayModal]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  if (isLoading) return <ProductSkeleton count={12} />;
  if (isError) return <div style={{ color: "red" }}>Error: {error.message}</div>;

  return (
    <>
      <Grid
        container
        className="Grid-padding"
        direction="row"
        justifyContent="flex-start"
        sx={{ flex: "1 1 1", display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "30px", rowGap: "32px" }}
      >
        {data?.pages?.map((page) =>
          page?.sets?.map((set, _ind) => (
            <Grid item className="product" key={set._id}>
              <SetCard
                isBtn={isEdit}
                openDrawer={() => toggleDrawer(set._id, set)}
                btnTitle={innerChnages ? (setsbtnTitles[set._id] && setsbtnTitles[set._id] == "Added" ? "Added" : "Add") : "Archive"}
                buttonClick={innerChnages ? () => handleAddSetButton(set) : () => openArchiveModal(set._id)}
                innerChnages={innerChnages}
                data={transformSetData(set)}
                isPresent={innerChnages && location?.state?.cataloguUpdate ? (isPresent[set._id] && isPresent[set._id] == true ? true : false) : false}
              />
            </Grid>
          ))
        )}
      </Grid>

      <RemoveModal open={removeModal} setOpen={setRemoveModal} btnInfo={modalBtnInfo} heading="Do you want to Archive the Set?" />

      <TrayModal cardId={viewset} open={showTrayModal} setOpen={setShowTrayModal} isView={isEdit === "click" ? false : true} innerChnages={innerChnages} currentSet={currentSet} />

      {isFetchingNextPage && <ProductSkeleton count={12} />}
      <div ref={ref} style={{ height: "20px", marginTop: "10px" }} />
    </>
  );
};

export default Sets;
